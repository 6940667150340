import { useState, useEffect } from "react";
import * as RA from "react-admin";

const AssetList = (props: RA.ListProps) => {
  return (
    <RA.List
      {...props}
      bulkActionButtons={false}
      sort={{ field: "venueId", order: "ASC" }}
    >
      <ListDataGrid />
    </RA.List>
  );
};

const ListDataGrid = (props: any) => {
  const [hasLabels, setHasLabels] = useState(false);

  useEffect(() => {
    if (props.data && [...Object.keys(props.data)].length > 0) {
      const hasAtLeastOneLabel =
        [...Object.keys(props.data)].filter((d) => props.data[d].label).length >
        0;
      setHasLabels(hasAtLeastOneLabel);
    }
  }, [props]);

  return (
    <RA.Datagrid rowClick="show">
      <RA.ReferenceField
        label="Venue"
        source="venueId"
        reference="venues"
        link={false}
      >
        <RA.TextField source="name" />
      </RA.ReferenceField>
      {hasLabels && <RA.TextField source="label" />}
      <RA.TextField source="name" />
      <RA.NumberField source="maxSeatsAvailable" label="Seats" />
      <RA.NumberField source="maxStandingRoomAvailable" label="SRO" />
      <RA.NumberField source="maxParking" label="Parking" />
      <RA.ShowButton />
    </RA.Datagrid>
  );
};

export default AssetList;
