// @ts-nocheck
import React, { useState } from "react";
import * as RA from "react-admin";
import { BookingMethod, AccessType } from "../../../utils/enums";
import {
  EditableDatagrid,
  RowForm,
  EditRowButton,
  DeleteWithConfirmIconButton,
} from "@react-admin/ra-editable-datagrid";
import {
  calculatePriceFromMargin,
  calculateWholeSalePriceFromMargin,
} from "../../../utils/margin";
import { IconButton, Tooltip } from "@material-ui/core";
import { Visibility, Notes } from "@material-ui/icons";
import useStyles from "../../forms/Styles";
import { convertShTzToIntlTz } from "../../../utils/timeZones";
import { useForm } from "react-final-form";
import NumberInput from "../../forms/NumberInput";

const CustomAction = (props: any) => {
  const { eventId } = props.record;
  const eventRecord = RA.useGetOne("events", eventId);

  if (!eventRecord.loaded) {
    return null;
  }

  return (
    <>
      <EditRowButton />
      <Tooltip title="Full Edit">
        <IconButton
          key="button"
          size="small"
          onClick={(e, b) => {
            console.log(e, b);
            e.stopPropagation();
            window.location.href = `${window.location.origin}/#/listings/${props.record.id}`;
          }}
        >
          <Notes color="primary" />
        </IconButton>
      </Tooltip>
      <Tooltip title="preview">
        <IconButton
          key="button"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            window.open(
              `${process.env.REACT_APP_BOX_OFFICE_DOMAIN}/event/${eventRecord?.data?.slug}?listing=${props.record.id}`,
              "_blank"
            );
          }}
        >
          <Visibility color="primary" />
        </IconButton>
      </Tooltip>
      <DeleteWithConfirmIconButton
        redirect={`/assets/${props.record.assetId}/show/1`}
      />
    </>
  );
};

const ListingList = ({
  assetId,
  venue,
}: {
  assetId: number;
  venue: Record<any, any>;
}) => {
  const styles = useStyles();
  const asset = RA.useGetOne("assets", assetId).data as RA.Record;

  if (!asset) {
    return null;
  }

  return (
    <EditableDatagrid
      editForm={<ListForm venue={venue} />}
      rowClick="edit"
      actions={<CustomAction />}
      className={styles.listingsForm}
      size="medium"
    >
      <RA.ReferenceField
        label="Event"
        source="eventId"
        reference="events"
        link={false}
      >
        <RA.TextField source="name" />
      </RA.ReferenceField>
      <RA.ReferenceField
        label="Date"
        source="eventId"
        reference="events"
        link={false}
      >
        <RA.DateField
          source="occursAt"
          locales="en-us"
          options={{ timeZone: convertShTzToIntlTz(venue.timeZone) }}
        />
      </RA.ReferenceField>
      <RA.SelectField
        source="bookingMethod"
        label="Booking Method"
        choices={BookingMethod}
      />
      {asset.seatingType === "suite" && (
        <RA.TextField source="accessType" label="Access Type" />
      )}
      <RA.NumberField label="Seats" source="seats" />
      <RA.NumberField
        label="Net Proceeds"
        source="wholesalePrice"
        options={{ style: "currency", currency: "USD" }}
      />
      <RA.NumberField
        label="Sells For"
        source="retailPrice"
        options={{ style: "currency", currency: "USD" }}
      />
    </EditableDatagrid>
  );
};

const ListForm = (props: any) => {
  const asset = RA.useGetOne("assets", props.record.assetId).data as RA.Record;

  if (!asset) {
    return null;
  }

  const [accessType, setAccessType] = useState(props.record.accessType);
  const [priceMultiplier, setPriceMultiplier] = useState(
    props.record.bookingMethod === "instant_book"
      ? asset.margin
      : asset.confirmMargin
  );

  return (
    <RowForm {...props} submitOnEnter={true}>
      <RA.ReferenceField
        label="Event"
        source="eventId"
        reference="events"
        link={false}
      >
        <RA.TextField source="name" />
      </RA.ReferenceField>
      <RA.ReferenceField
        label="Date"
        source="eventId"
        reference="events"
        link={false}
      >
        <RA.DateField
          source="occursAt"
          locales="en-us"
          options={{ timeZone: convertShTzToIntlTz(props.venue.timeZone) }}
        />
      </RA.ReferenceField>
      <BookingMethodInput
        record={props.record}
        asset={asset}
        setPriceMultiplier={setPriceMultiplier}
      />
      {asset.seatingType === "suite" && (
        <RA.SelectInput
          source="accessType"
          choices={AccessType}
          label="Access Type"
          validate={RA.required()}
          onChange={(e) => setAccessType(e.target.value)}
        />
      )}
      {accessType !== "private" ? (
        <NumberInput
          label="Seats"
          source="seats"
          validate={[
            RA.required(),
            RA.minValue(1),
            RA.maxValue(asset.maxSeatsAvailable),
          ]}
        />
      ) : (
        <RA.NumberField label="Seats" source="seats" />
      )}
      <WholeSalePriceInput
        wholesalePrice={props.record.wholesalePrice}
        priceMultiplier={priceMultiplier}
      />
      <RetailPriceInput
        retailPrice={undefined}
        priceMultiplier={priceMultiplier}
      />
    </RowForm>
  );
};

const BookingMethodInput = ({ record, asset, setPriceMultiplier }) => {
  const styles = useStyles();
  const form = useForm();

  return (
    <RA.SelectInput
      source="bookingMethod"
      choices={BookingMethod}
      validate={RA.required()}
      className={styles.input}
      initialValue={record.bookingMethod}
      onChange={(e) => {
        const priceMultiplier =
          e.target.value === "instant_book"
            ? asset.margin
            : asset.confirmMargin;
        const wholeSalePrice = form.getFieldState("wholesalePrice").value;
        const retailPrice = form.getFieldState("retailPrice").value;
        setPriceMultiplier(priceMultiplier);
        if (!wholeSalePrice && !retailPrice) {
          return;
        }
        const calculatedPrice = calculatePriceFromMargin(
          wholeSalePrice,
          priceMultiplier
        );
        if (retailPrice !== calculatedPrice) {
          form.change("retailPrice", calculatedPrice);
        }
      }}
    />
  );
};

const WholeSalePriceInput = ({ wholesalePrice, priceMultiplier }) => {
  const styles = useStyles();
  const form = useForm();

  return (
    <NumberInput
      source="wholesalePrice"
      label="Net Proceeds"
      validate={[RA.required(), RA.minValue(1)]}
      initialValue={wholesalePrice}
      onChange={(e) => {
        const val = parseInt(e.target.value);
        if (isNaN(val)) {
          return;
        }
        const _retailPrice = Math.round(
          calculatePriceFromMargin(val, priceMultiplier)
        );
        form.change("wholesalePrice", val);
        form.change("retailPrice", _retailPrice);
      }}
      className={styles.input}
    />
  );
};

const RetailPriceInput = ({ retailPrice, priceMultiplier }) => {
  const styles = useStyles();
  const form = useForm();

  return (
    <NumberInput
      source="retailPrice"
      label="Sells For"
      validate={[RA.required(), RA.minValue(1)]}
      initialValue={retailPrice}
      onChange={(e) => {
        const val = parseInt(e.target.value);
        if (isNaN(val)) {
          return;
        }
        const _wholeSalePrice = Math.round(
          calculateWholeSalePriceFromMargin(val, priceMultiplier)
        );
        form.change("wholesalePrice", _wholeSalePrice);
        form.change("retailPrice", val);
      }}
      className={styles.input}
    />
  );
};

export default ListingList;
