export const calculatePriceFromMargin = (cost: number, margin: number) => {
  return cost / (1 - margin / 100);
};

export const calculateWholeSalePriceFromMargin = (
  retailCost: number,
  margin: number
) => {
  return retailCost * (1 - margin / 100);
};

export const calculateMarginPercentage = (cost: number, revenue: number) => {
  return ((revenue - cost) / revenue) * 100;
};
