// @ts-nocheck
import { useEffect, useState } from "react";
import * as RA from "react-admin";
import queryString from "query-string";
import HtmlSanitize from "../../forms/HtmlSanitize";
import useStyles from "../../forms/Styles";
import { useForm } from "react-final-form";
import {
  calculatePriceFromMargin,
  calculateWholeSalePriceFromMargin,
} from "../../../utils/margin";
import { AccessType, BookingMethod, SeatingType } from "../../../utils/enums";
import { Divider } from "@material-ui/core";
import CustomToolBar from "../../layout/CustomToolBar";
import NumberInput from "../../forms/NumberInput";

const ListingForm = ({ view = "create", ...props }) => {
  const styles = useStyles();
  const { record } = props;
  const params = queryString.parse(window.location.href.split("?")[1]);

  if (params?.assetId) {
    record.assetId = parseInt(params.assetId);
  }
  if (params?.eventId) {
    record.eventId = parseInt(params.eventId);
  }
  if (params?.tz) {
    record.tz = params.tz;
  }
  if (!record.tz) {
    record.tz = "America/Denver";
  }

  const redirectRoute = `/assets/${record.assetId}/show/1`;
  const accessTypeOptions = AccessType.filter((at) => at.id !== "NA");
  const asset = RA.useGetOne("assets", record.assetId).data as RA.Record;
  const [accessType, setAccessType] = useState(record?.accessType || "private");
  const [priceMultiplier, setPriceMultiplier] = useState(null);

  // Set access type
  useEffect(() => {
    if (asset) {
      if (asset.seatingType === "suite" && record.accessType === "NA") {
        setAccessType("private");
      }
      if (asset.seatingType !== "suite") {
        setAccessType("NA");
      }
    }
  }, [asset]);

  if (!asset) {
    return null;
  }

  if (asset.margin && !priceMultiplier) {
    setPriceMultiplier(asset.margin);
  }

  return (
    <RA.SimpleForm
      {...props}
      toolbar={<CustomToolBar view={view} deleteRedirectPath={redirectRoute} />}
      redirect={redirectRoute}
    >
      <RA.ReferenceField
        label="Event Name"
        source="eventId"
        reference="events"
        link={false}
      >
        <RA.TextField source="name" className={styles.input} />
      </RA.ReferenceField>
      <RA.ReferenceField
        label="Date"
        source="eventId"
        reference="events"
        link={false}
      >
        <RA.DateField
          source="occursAt"
          label="Date"
          className={styles.input}
          locales="en-us"
          options={{ timeZone: record.tz }}
        />
      </RA.ReferenceField>
      <RA.ReferenceField
        label="Asset Name"
        source="assetId"
        reference="assets"
        link={false}
      >
        <RA.TextField source="name" className={styles.input} />
      </RA.ReferenceField>
      <RA.ReferenceField
        label="Premium Seating Type"
        source="assetId"
        reference="assets"
        link={false}
      >
        <RA.SelectField
          source="seatingType"
          className={styles.input}
          choices={SeatingType}
        />
      </RA.ReferenceField>
      <HtmlSanitize>
        <Divider className={styles.divider} />
      </HtmlSanitize>
      <BookingMethodInput
        asset={asset}
        setPriceMultiplier={setPriceMultiplier}
        record={record}
      />
      {asset.seatingType === "suite" ? (
        <RA.SelectInput
          source="accessType"
          label="Access Type"
          validate={RA.required()}
          choices={accessTypeOptions}
          className={styles.input}
          onChange={(e) => setAccessType(e.target.value)}
          initialValue={accessType}
        />
      ) : (
        <RA.SelectInput
          source="accessType"
          label="Access Type"
          validate={RA.required()}
          choices={AccessType}
          className={styles.displayNone}
          initialValue={accessType}
          disabled
        />
      )}

      {accessType !== "private" && (
        <NumberInput
          source="seats"
          label="Seats Available"
          helperText={`how many seats out of the assets ${asset.maxSeatsAvailable} seats are available for this listing?`}
          min={1}
          validate={[
            RA.required(),
            RA.minValue(1),
            RA.maxValue(asset.maxSeatsAvailable),
          ]}
          className={styles.input}
          initialValue={record?.seats || 1}
        />
      )}
      <WholeSalePriceInput
        wholesalePrice={undefined}
        priceMultiplier={priceMultiplier}
        accessType={accessType}
      />
      <RetailPriceInput
        retailPrice={undefined}
        priceMultiplier={priceMultiplier}
        accessType={accessType}
      />
      <RA.TextInput
        source="notes"
        multiline
        rows={8}
        className={styles.input}
      />
    </RA.SimpleForm>
  );
};

const BookingMethodInput = ({ asset, setPriceMultiplier, record }) => {
  const styles = useStyles();
  const form = useForm();

  return (
    <RA.SelectInput
      source="bookingMethod"
      choices={BookingMethod}
      validate={RA.required()}
      className={styles.input}
      initialValue={record?.bookingMethod || "instant_book"}
      onChange={(e) => {
        const priceMultiplier =
          e.target.value === "instant_book"
            ? asset.margin
            : asset.confirmMargin;
        const wholeSalePrice = form.getFieldState("wholesalePrice").value;
        const retailPrice = form.getFieldState("retailPrice").value;
        setPriceMultiplier(priceMultiplier);
        if (!wholeSalePrice && !retailPrice) {
          return;
        }
        const calculatedPrice = calculatePriceFromMargin(
          wholeSalePrice,
          priceMultiplier
        );
        if (retailPrice !== calculatedPrice) {
          form.change("retailPrice", calculatedPrice);
        }
      }}
    />
  );
};

const WholeSalePriceInput = ({
  wholesalePrice,
  priceMultiplier,
  accessType,
}) => {
  const styles = useStyles();
  const form = useForm();

  return (
    <NumberInput
      source="wholesalePrice"
      label={
        accessType === "private" ? "Net Proceeds" : "Net Proceeds Per Ticket"
      }
      helperText={
        accessType === "private"
          ? "Amount you will be paid when the listing sells (sales price minus fees)"
          : "Amount you will be paid per ticket when the listing sells (sales price minus fees)"
      }
      validate={[RA.required(), RA.minValue(1)]}
      initialValue={wholesalePrice}
      onChange={(e) => {
        const val = parseInt(e.target.value);
        if (isNaN(val)) {
          return;
        }
        const _retailPrice = Math.round(
          calculatePriceFromMargin(val, priceMultiplier)
        );
        form.change("wholesalePrice", val);
        form.change("retailPrice", _retailPrice);
      }}
      className={styles.input}
    />
  );
};

const RetailPriceInput = ({ retailPrice, priceMultiplier, accessType }) => {
  const styles = useStyles();
  const form = useForm();

  return (
    <NumberInput
      source="retailPrice"
      label={accessType === "private" ? "Sells For" : "Sells For Per Ticket"}
      helperText={
        accessType === "private"
          ? "Price that the customer pays for this listing"
          : "Price that the customer pays per ticket pays for this listing"
      }
      validate={[RA.required(), RA.minValue(1)]}
      initialValue={retailPrice}
      onChange={(e) => {
        const val = parseInt(e.target.value);
        if (isNaN(val)) {
          return;
        }
        const _wholeSalePrice = Math.round(
          calculateWholeSalePriceFromMargin(val, priceMultiplier)
        );
        form.change("wholesalePrice", _wholeSalePrice);
        form.change("retailPrice", val);
      }}
      className={styles.input}
    />
  );
};

export default ListingForm;
