import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  input: {
    width: 500,
    maxWidth: "100%",
    textTransform: "capitalize",
  },
  alert: {
    marginBottom: "1rem",
  },
  search: {
    width: 300,
  },
  divider: {
    marginBottom: "1rem",
  },
  header: {
    marginBottom: "1rem",
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
  },
  deleteButton: {
    color: "#c64c38",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  venueName: {
    margin: "1rem",
  },
  listingsForm: {
    width: "1300px",
    textTransform: "capitalize",
    tableLayout: "fixed",
  },
  table: {
    width: "1300px",
    textTransform: "capitalize",
  },
  displayNone: {
    display: "none",
  },
  marginBottom2: {
    marginBottom: "2rem",
  },
});

export default useStyles;
