import { useEffect, useState } from "react";
import { Admin, Resource } from "react-admin";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { Route } from "react-router-dom";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import { raFormLayoutLanguageEnglish } from "@react-admin/ra-form-layout";
import CustomLayout from "./layout/CustomLayout";
import DataProvider from "./providers/DataProvider";
import Assets from "./resources/assets";
import Listings from "./resources/listings";
import { createTheme } from "@material-ui/core/styles";
import PayoutSchedule from "./layout/PayoutSchedule";

// Suitehop Colors
const theme = createTheme({
  palette: {
    primary: {
      main: "#2B8DBE",
    },
    secondary: {
      main:
        process.env.REACT_APP_DATA_PROVIDER_URI === "https://api.suitehop.com"
          ? "#000000"
          : "#FF0000",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#c64c38",
    },
  },
});
const messages = {
  en: { ...englishMessages, ...raFormLayoutLanguageEnglish },
};
const is18nProvider = polyglotI18nProvider(() => messages.en, "en");

const Dashboard = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [authToken, setAuthToken] = useState<string | null>(null);

  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
        },
      });
      setAuthToken(token);
    };
    getToken();
  }, [getAccessTokenSilently]);

  if (!authToken) {
    return <div>Loading...</div>;
  }

  const initialState = {
    admin: { ui: { sidebarOpen: true } },
  };

  return (
    <Admin
      theme={theme}
      dataProvider={DataProvider(authToken, user?.sub, user?.email)}
      layout={CustomLayout}
      i18nProvider={is18nProvider}
      initialState={initialState}
      customRoutes={[
        <Route exact path="/payout-schedule" component={PayoutSchedule} />,
      ]}
    >
      <Resource name="assets" options={{ label: "My Suites" }} {...Assets} />
      <Resource name="listings" {...Listings} />
      <Resource name="events" />
      <Resource name="venues" />
      <Resource name="orders" />
      <Resource name="customers" />
    </Admin>
  );
};

export default withAuthenticationRequired(Dashboard);
