// Note: These would be much better if we could somehow import these directly from our Prisma schema,
// otherwise we are going to have drift at some point

const formatted = (arr: string[]): Record<string, string>[] =>
  arr.map((choice) => ({
    id: choice.replace(" ", "_").replace("/", "_"),
    name: choice,
  }));

export const VenueType = formatted([
  "amphitheater",
  "arena",
  "festival",
  "golf",
  "motorsports",
  "stadium",
  "tennis",
]);

export const PerformerType = formatted(["team", "artist", "big event"]);

export const TimeZone = formatted([
  "us/pacific",
  "us/arizona",
  "us/mountain",
  "us/central",
  "us/eastern",
]);

export const Category = formatted([
  "nfl",
  "nba",
  "nhl",
  "mlb",
  "mls",
  "ncaa",
  "sports",
  "concerts",
  "football",
  "soccer",
  "baseball",
  "basketball",
  "hockey",
  "special events",
  "other events",
  "xfl",
  "tennis",
  "golf",
  "default",
  "stadium",
]);

export const FeedParserGroup = formatted([
  "party",
  "party suite",
  "c level",
  "default",
]);

export const ListingSource = formatted([
  "suitehop admin",
  "listing partner admin",
  "ticket evolution",
]);

export const AccessType = formatted(["private", "shared", "NA"]);

export const BookingMethod = formatted(["instant book", "confirm"]);

export const SellerListingFrequency = formatted([
  "single event",
  "multiple events",
]);

export const SellerApplicationStatus = formatted([
  "pending",
  "approved",
  "denied",
]);

export const ListingStatus = formatted([
  "available",
  "sold",
  "sold elsewhere",
  "expired",
]);

export const SeatingType = formatted([
  "suite",
  "vip seating",
  "vip club",
  "shared access",
  "vip box",
  "vip package",
]);

export const Country = formatted(["usa", "canada"]);

export const CateringType = formatted([
  "not catered",
  "all inclusive with alcohol",
  "premium buffet",
  "catering package",
]);
