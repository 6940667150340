import * as RA from "react-admin";
import ListingForm from "./_Form";

const ListingEdit = (props: RA.CreateProps) => {
  return (
    <RA.Edit {...props}>
      <ListingForm view="edit" />
    </RA.Edit>
  );
};

export default ListingEdit;
