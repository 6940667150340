// @ts-nocheck
import simpleRestProvider from "ra-data-simple-rest";
import * as RA from "react-admin";

const DataProvider = (
  authToken: string | null,
  userId: string | undefined = "anonymous",
  userEmail: string | undefined = "anonymous"
): RA.DataProvider => {
  const user = {
    sub: userId,
    email: userEmail,
  };
  const httpClient = (url: string, options: RA.fetchUtils.Options = {}) => {
    if (!options.headers) {
      options.headers = new Headers();
    }
    options.headers.set("Content-Type", "application/json");
    options.headers.set("Authorization", `Bearer ${authToken}`);
    options.headers.set("x-admin-request", "true");
    options.headers.set("x-admin-user-id", userId);
    options.headers.set("x-admin-user-email", userEmail);
    return RA.fetchUtils.fetchJson(url, options);
  };

  const defaultDataProvider = simpleRestProvider(
    process.env.REACT_APP_DATA_PROVIDER_URI || "http://localhost:5000",
    httpClient
  );

  return {
    ...defaultDataProvider,
    create: async (resource: string, params: any) => {
      const isListingResource = resource === "listings";

      if (isListingResource) {
        params = await handleListingsParams(params, authToken, user);
      }

      return defaultDataProvider.create(resource, params).then((res) => {
        res.data = removeFormattedArrayProps(res.data);

        if (isListingResource) {
          res.data.seats = getMaxTicketsAvailable(res.data.ticketGroupSizes);
        }

        return res;
      });
    },
    update: async (resource: string, params: any) => {
      const isListingResource = resource === "listings";

      if (isListingResource) {
        params = await handleListingsParams(params, authToken, user);
      }

      return defaultDataProvider.update(resource, params).then((res) => {
        res.data = removeFormattedArrayProps(res.data);

        if (isListingResource) {
          res.data.seats = getMaxTicketsAvailable(res.data.ticketGroupSizes);
        }

        return res;
      });
    },
    getOne: async (resource: string, params: any) => {
      return defaultDataProvider.getOne(resource, params).then((res) => {
        res.data = removeFormattedArrayProps(res.data);

        if (resource === "listings") {
          res.data.seats = getMaxTicketsAvailable(res.data.ticketGroupSizes);
        }

        return res;
      });
    },
    getManyReference: async (resource: string, params: any) => {
      return defaultDataProvider
        .getManyReference(resource, params)
        .then((res) => {
          res.data.map((resource) => removeFormattedArrayProps(resource));

          if (resource === "orders") {
            res.data.map((i) => {
              i.eventId = i.listing.event.id;
              delete i.listing;
              return i;
            });
          }

          if (resource === "listings") {
            res.data.map((listing) => {
              listing.seats = getMaxTicketsAvailable(listing.ticketGroupSizes);
              return listing;
            });
          }

          return res;
        });
    },
  };
};

const getDataFromCustomRoute = async (url, authToken, user = {}) => {
  const data = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      "x-admin-request": true,
      "x-admin-user-id": user.sub,
      "x-admin-user-email": user.email,
    },
  });

  return data.json();
};

const handleListingsParams = async (params: any, authToken, user) => {
  const asset = await getDataFromCustomRoute(
    `${process.env.REACT_APP_DATA_PROVIDER_URI}/assets/${params.data.assetId}`,
    authToken,
    user
  );
  const accessType = params.data.accessType || "private";
  const ticketsIncluded =
    accessType === "private"
      ? asset.maxSeatsAvailable
      : params.data.seats || asset.maxSeatsAvailable;
  const ticketGroupSizes =
    accessType === "private"
      ? [ticketsIncluded]
      : Array.from({ length: ticketsIncluded }, (_, i) => i + 1);

  delete params.data.seats;
  delete params.data.tz;

  params.data = {
    ...params.data,
    ticketGroupSizes,
  };

  return params;
};

const removeFormattedArrayProps = (data: Record<any, any>) => {
  const includedKeys = ["ticketGroupSizes"];

  Object.keys(data).forEach((key) => {
    if (includedKeys.includes(key) && data[key].length) {
      data[key] = data[key].map((i: { field: any }) => i.field);
    }
  });

  return data;
};

const getMaxTicketsAvailable = (ticketGroupSizes: number[]) => {
  return Math.max(...ticketGroupSizes);
};

export default DataProvider;
