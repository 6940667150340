import * as RA from "react-admin";
import useStyles from "../../forms/Styles";
import React from "react";
import { convertShTzToIntlTz } from "../../../utils/timeZones";

const OrderList = ({ venue }: { venue: Record<any, any> }) => {
  const styles = useStyles();

  return (
    <RA.Datagrid className={styles.table}>
      <RA.TextField source="number" label="Order #" />
      <RA.ReferenceField
        label="Event"
        source="eventId"
        reference="events"
        link={false}
      >
        <RA.TextField source="name" />
      </RA.ReferenceField>
      <RA.ReferenceField
        label="Date"
        source="eventId"
        reference="events"
        link={false}
      >
        <RA.DateField
          source="occursAt"
          locales="en-us"
          options={{ timeZone: convertShTzToIntlTz(venue.timeZone) }}
        />
      </RA.ReferenceField>
      <RA.NumberField
        source="wholesalePrice"
        label="Net Proceeds"
        options={{ style: "currency", currency: "USD" }}
      />
      <RA.NumberField
        source="salePrice"
        label="Sold For"
        options={{ style: "currency", currency: "USD" }}
      />
      <RA.NumberField source="numberTickets" />
      {/*<CreateListingLink assetId={assetId} />*/}
    </RA.Datagrid>
  );
};

export default OrderList;
