import * as RA from "react-admin";
import EventList from "../events/list";
import ListingsList from "../listings/list";
import OrdersList from "../orders/list";
import Alert from "@material-ui/lab/Alert";
import HtmlSanitize from "../../forms/HtmlSanitize";
import useStyles from "../../forms/Styles";
import { Typography } from "@material-ui/core";
import { cloneElement } from "react";
import { CateringType } from "../../../utils/enums";

const PersistedNameContainer = (props: any) => {
  const styles = useStyles();
  const venue = RA.useGetOne("venues", props.record.venueId).data as RA.Record;

  if (!venue) {
    return null;
  }

  return (
    <HtmlSanitize>
      <Typography variant="h5" className={styles.venueName}>
        {venue.name}: {props.record.name}{" "}
        {props.record.label && `- ${props.record.label}`}
      </Typography>
      {cloneElement(props.children, { ...props, venue })}
    </HtmlSanitize>
  );
};

const AssetShow = (props: any) => {
  const styles = useStyles();
  const assetId = props.record.id;

  return (
    <RA.TabbedShowLayout>
      <RA.Tab label="Unlisted Events">
        <RA.ReferenceManyField
          label=""
          reference="events"
          target="unlistedEventsByAssetId"
          pagination={<RA.Pagination />}
          sort={{ field: "occursAt", order: "ASC" }}
        >
          <EventList assetId={assetId} venue={props.venue} />
        </RA.ReferenceManyField>
      </RA.Tab>
      <RA.Tab label="Active Listings">
        <RA.ReferenceManyField
          label=""
          reference="listings"
          target="listingsByAssetId"
          pagination={<RA.Pagination />}
          perPage={10}
          sort={{ field: "eventId", order: "ASC" }}
        >
          <ListingsList assetId={assetId} venue={props.venue} />
        </RA.ReferenceManyField>
      </RA.Tab>
      <RA.Tab label="Orders">
        <RA.ReferenceManyField
          label=""
          reference="orders"
          target="approvedOrdersByAssetId"
          pagination={<RA.Pagination />}
          perPage={10}
          // sort={{ field: "eventId", order: "ASC" }}
        >
          <OrdersList venue={props.venue} />
        </RA.ReferenceManyField>
      </RA.Tab>
      <RA.Tab label="Suite Info">
        <HtmlSanitize>
          <Alert className={styles.alert} severity="info">
            To update the configuration of your suite, email
            service@suitehop.com
          </Alert>
        </HtmlSanitize>
        <RA.TextField source="name" className={styles.input} />
        {props.record.label && (
          <RA.TextField source="label" className={styles.input} />
        )}
        <RA.TextField
          source="seatingType"
          label="Premium Seating Type"
          className={styles.input}
        />
        <RA.NumberField
          source="maxSeatsAvailable"
          label="Number of tickets included"
          className={styles.input}
        />
        <RA.NumberField
          source="maxStandingRoomAvailable"
          label="Number of SROs available"
          className={styles.input}
        />
        <RA.NumberField
          source="maxParking"
          label="Number of Parking Passes Included"
          className={styles.input}
        />
        <RA.SelectField
          source="cateringType"
          label="Catering Type"
          className={styles.input}
          choices={CateringType}
        />
      </RA.Tab>
    </RA.TabbedShowLayout>
  );
};

export default (props: RA.ShowProps) => {
  return (
    <RA.Show {...props}>
      <PersistedNameContainer>
        <AssetShow />
      </PersistedNameContainer>
    </RA.Show>
  );
};
