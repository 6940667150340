import * as RA from "react-admin";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Add from "@material-ui/icons/Add";
import useStyles from "../../forms/Styles";
import { convertShTzToIntlTz } from "../../../utils/timeZones";
import queryString from "query-string";

type CreateListingLinkType = RA.ButtonProps & {
  assetId: number;
  tz: string | undefined;
};
const CreateListingLink = ({ record, assetId, tz }: CreateListingLinkType) => {
  const listingInfo = {
    assetId,
    tz,
    eventId: record ? record.id : null,
  };
  const search = `${queryString.stringify(listingInfo)}`;
  return (
    <Button
      color="primary"
      onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
      component={Link}
      to={{
        pathname: "/listings/create",
        search,
      }}
    >
      <Add />
      Create Listing
    </Button>
  );
};

type EventListType = RA.ListProps & {
  assetId: number;
  venue: Record<any, any>;
};
const EventList = ({ assetId, venue }: EventListType) => {
  const styles = useStyles();

  return (
    <RA.Datagrid className={styles.table}>
      <RA.TextField source="name" />
      <RA.DateField
        source="occursAt"
        label="Date"
        locales="en-us"
        options={{ timeZone: convertShTzToIntlTz(venue.timeZone) }}
      />
      <CreateListingLink
        assetId={assetId}
        tz={convertShTzToIntlTz(venue.timeZone)}
      />
    </RA.Datagrid>
  );
};

export default EventList;
