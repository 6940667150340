import * as React from "react";
import Card from "@material-ui/core/Card";
import { CardHeader, CardContent, Typography } from "@material-ui/core";
import useStyles from "../forms/Styles";

const PayoutSchedule = () => {
  const styles = useStyles();

  return (
    <Card>
      <CardHeader title="2023 SuiteHop Listing Partner Payout Dates" />
      <CardContent>
        <Typography variant="h6">January</Typography>
        <Typography>January 3, 2023</Typography>
        <Typography>January 17, 2023</Typography>
        <Typography className={styles.marginBottom2}>
          January 31, 2023
        </Typography>

        <Typography variant="h6">February</Typography>
        <Typography>February 14, 2023</Typography>
        <Typography className={styles.marginBottom2}>
          February 28, 2023
        </Typography>

        <Typography variant="h6">March</Typography>
        <Typography>March 14, 2023</Typography>
        <Typography className={styles.marginBottom2}>March 28, 2023</Typography>

        <Typography variant="h6">April</Typography>
        <Typography>April 11, 2023</Typography>
        <Typography className={styles.marginBottom2}>April 25, 2023</Typography>

        <Typography variant="h6">May</Typography>
        <Typography>May 9, 2023</Typography>
        <Typography className={styles.marginBottom2}>May 22, 2023</Typography>

        <Typography variant="h6">June</Typography>
        <Typography>June 2, 2023</Typography>
        <Typography>June 13, 2023</Typography>
        <Typography className={styles.marginBottom2}>June 20, 2023</Typography>

        <Typography variant="h6">July</Typography>
        <Typography>July 5, 2023</Typography>
        <Typography className={styles.marginBottom2}>July 18, 2023</Typography>

        <Typography variant="h6">August</Typography>
        <Typography>August 1, 2023</Typography>
        <Typography>August 15, 2023</Typography>
        <Typography className={styles.marginBottom2}>
          August 29, 2023
        </Typography>

        <Typography variant="h6">September</Typography>
        <Typography>September 12, 2023</Typography>
        <Typography className={styles.marginBottom2}>
          September 26, 2023
        </Typography>

        <Typography variant="h6">October</Typography>
        <Typography>October 10, 2023</Typography>
        <Typography className={styles.marginBottom2}>
          October 24, 2023
        </Typography>

        <Typography variant="h6">November</Typography>
        <Typography>November 7, 2023</Typography>
        <Typography className={styles.marginBottom2}>
          November 21, 2023
        </Typography>

        <Typography variant="h6">December</Typography>
        <Typography>December 5, 2023</Typography>
        <Typography className={styles.marginBottom2}>
          December 19, 2023
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PayoutSchedule;
