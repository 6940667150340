import * as RA from "react-admin";
import ListingForm from "./_Form";

const ListingCreate = (props: RA.CreateProps) => {
  return (
    <RA.Create {...props}>
      <ListingForm view="create" />
    </RA.Create>
  );
};

export default ListingCreate;
